/* RESET */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
[hidden],
template {
  display: none;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.2;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

iframe {
  border: none;
}

/* CUSTOM BASE */

body {
  color: #656162;
  font-size: 15px;
  font-family: Effra, Helvetica, sans-serif;
}

body > div#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
body > div#root > main {
  flex-grow: 1;
  padding: 2rem 1rem;
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
}

.theme p,
.theme h1,
.theme h2,
.theme h3,
.theme h4,
.theme h5,
.theme h6,
.theme ul,
.theme ol,
.theme dl,
.theme address {
  margin-bottom: 1rem;
  font-size: revert;
}

.theme p,
.theme h5,
.theme h6,
.theme ul,
.theme ol,
.theme dl,
.theme address {
  font-size: 1.12rem;
}

.theme dl,
.theme dd,
.theme ul,
.theme ol {
  margin-left: 1rem;
  padding-left: 1rem;
  list-style: revert;
}
.theme dt {
  font-weight: bold;
}
.theme dd {
  margin-bottom: 1rem;
}

.theme a {
  color: #843f8f;
  text-decoration: underline;
  word-break: break-word;
}
.theme a:hover,
.theme a:focus {
  text-decoration: none;
}

.theme .pl-4 {
  padding-left: 1rem;
}
